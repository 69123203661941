(function ($) {

    $(document).ready(function() {

        $(".video").click(function () {
            var theModal = $(this).data("target"),
                videoSRC = $(this).attr("data-video");
            $(theModal + ' iframe').attr('src', videoSRCauto);
            $(theModal + ' button.close').click(function () {
                $(theModal + ' iframe').attr('src', videoSRC);
            });
        });
        $('#videoModal').on('hidden.bs.modal', function () {
            $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
        });

        // Smooth scrolling for ankers
        $(".smooth").on('click', function (event) {
            // store hash
            var hash = this.hash;
            if (hash !== "" && hash !== "#myCarousel") {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $(this.hash).offset().top - 90
                }, 500, function () {
                    window.location.hash = hash;
                });
            }
        });
    });

})(jQuery);
